body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html {
	scroll-behavior: smooth;
}
html,
body {
	max-width: 100%;
	overflow-x: hidden;
}

@font-face {
	font-family: 'Poppins';
	src:
		local('Poppins'),
		url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'Poppins';
	src:
		local('Poppins'),
		url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'Poppins';
	src:
		local('Poppins'),
		url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src:
		local('Poppins'),
		url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'Poppins';
	src:
		local('Poppins'),
		url('./fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar-track {
	background-color: #f2f2f2;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb {
	background: #d1d1d1;
	border-radius: 5px;
	height: 30px;
}

::-webkit-scrollbar-thumb:hover {
	background: #818a8f69;
}
